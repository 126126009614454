
























































































































































































































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { Messages } from "@/models/enums/messages.enum";
import {
  CustomListCategory,
  DataListAssetBook,
  ResponseFindAddValue,
} from "@/models/interface/assets.interface";
import {
  ContactData,
  ResponseListContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { assetsServices } from "@/services/assets.service";
import { contactServices } from "@/services/contact.service";
import { masterServices } from "@/services/master.service";
import {
  formatterNumber,
  numericOnly,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import Vue from "vue";
import { mapState } from "vuex";
import { useContactListFindById } from "@/hooks";
import { RequestAssetAdditions } from "./types";
export default Vue.extend({
  name: "AssetAdditionsCreate",
  components: {
    VNodes: {
      functional: true,
      render: (_h, ctx) => ctx.props.vnodes,
    },
  },
  data() {
    this.getListStatus = debounceProcess(this.getListStatus, 200);
    this.getListAssetCategories = debounceProcess(
      this.getListAssetCategories,
      200
    );
    this.getListAssetBook = debounceProcess(this.getListAssetBook, 200);
    this.getDataAssetTag = debounceProcess(this.getDataAssetTag, 200);
    return {
      InputNumberOnly: numericOnly,
      loadingListAssetBook: false as boolean,
      loadingListAssetCategory: false as boolean,
      dataListCategory: [] as CustomListCategory[],
      dataListBook: [] as DataListAssetBook[],
      dataDetail: {} as ResponseFindAddValue,
      loadingFind: false as boolean,
      loadingPosting: false as boolean,
      loadingListSupplier: false as boolean,
      loadingStatus: false as boolean,
      leasingCheckbox: false,
      depreaciateCheckbox: true,
      form: this.$form.createForm(this, { name: "addToAsset" }),
      formFindAsset: this.$form.createForm(this, { name: "findAsset" }),
      dataSourceSupplier: [] as ContactData[],
      dataListStatus: [] as ResponseListMaster[],
      dataType: [] as string[],
      dataModel: [] as string[],
      formRulesFindAsset: {
        assetNumber: {
          label: "lbl_asset_number",
          name: "assetNumber",
          placeholder: "lbl_asset_number_placeholder",
          decorator: ["assetNumber"],
        },
        serialNumber: {
          label: "lbl_serial_number",
          name: "serialNumber",
          placeholder: "lbl_serial_number_placeholder",
          decorator: ["serialNumber"],
        },
        kodeUnit: {
          label: "lbl_unit_code",
          name: "kodeUnit",
          placeholder: "lbl_unit_code_placeholder",
          decorator: ["kodeUnit"],
        },
      },
      formRules: {
        type: {
          label: "lbl_type",
          name: "type",
          placeholder: "lbl_type_placeholder",
          decorator: [
            "type",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        assetNumber: {
          label: "lbl_asset_number",
          name: "assetNumber",
          placeholder: "lbl_asset_number_placeholder",
          decorator: [
            "assetNumber",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        book: {
          label: "lbl_book",
          name: "book",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "book",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        category: {
          label: "lbl_category",
          name: "category",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "category",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        depreciationAccount: {
          label: "lbl_depreciation_account",
          name: "depreciationAccount",
          // placeholder: "Insert your Depreciation Account",
          placeholder: "<auto generate>",
          decorator: ["depreciationAccount"],
        },
        accumulatedDepreciationAccount: {
          label: "lbl_accumulated_depreciation_account",
          name: "accumulatedDepreciationAccount",
          // placeholder: "Insert your Accumulated Depreciation Account",
          placeholder: "<auto generate>",
          decorator: ["accumulatedDepreciationAccount"],
        },
        expenseAccount: {
          label: "lbl_expense_account",
          name: "expenseAccount",
          // placeholder: "Insert your Accumulated Depreciation Account",
          placeholder: "<auto generate>",
          decorator: [
            "expenseAccount",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        description: {
          label: "lbl_description",
          name: "description",
          placeholder: "lbl_description_placeholder",
          decorator: [
            "description",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        unit: {
          label: "lbl_qty",
          name: "unit",
          placeholder: "lbl_qty_placeholder",
          decorator: [
            "unit",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        serialNumber: {
          label: "lbl_serial_number",
          name: "serialNumber",
          placeholder: "lbl_serial_number_placeholder",
          decorator: [
            "serialNumber",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        unitCode: {
          label: "lbl_unit_code",
          name: "unitCode",
          placeholder: "lbl_unit_code_placeholder",
          decorator: [
            "unitCode",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        location: {
          label: "lbl_location",
          name: "location",
          placeholder: "lbl_location_placeholder",
          decorator: [
            "location",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        status: {
          label: "lbl_status",
          name: "status",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "status",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        invoiceNumber: {
          label: "lbl_invoice_number",
          name: "invoiceNumber",
          placeholder: "lbl_invoice_number_placeholder",
          decorator: [
            "invoiceNumber",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        poNumber: {
          label: "lbl_po_number",
          name: "poNumber",
          placeholder: "lbl_po_number_placeholder",
          decorator: [
            "poNumber",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        supplierName: {
          label: "lbl_supplier_name",
          name: "supplierName",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "supplierName",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        supplierLocation: {
          label: "lbl_supplier_location",
          name: "supplierLocation",
          placeholder: "lbl_supplier_location_placeholder",
          decorator: [
            "supplierLocation",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        employeeName: {
          label: "lbl_employee_name",
          name: "employeeName",
          placeholder: "lbl_employee_name_placeholder",
          decorator: [
            "employeeName",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        warranty: {
          label: "lbl_warranty",
          name: "warranty",
          placeholder: "lbl_warranty_placeholder",
          decorator: [
            "warranty",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        model: {
          label: "lbl_model",
          name: "model",
          placeholder: "lbl_model_placeholder",
          decorator: [
            "model",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        assetCost: {
          label: "lbl_asset_cost",
          name: "assetCost",
          placeholder: "lbl_asset_cost_placeholder",
          decorator: [
            "assetCost",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        lifeMonths: {
          label: "lbl_life_months",
          name: "lifeMonths",
          placeholder: "lbl_life_months_placeholder",
          decorator: [
            "lifeMonths",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        acquisitionDate: {
          label: "lbl_acquisition_date",
          name: "acquisitionDate",
          placeholder: "lbl_acquisition_date_placeholder",
          decorator: [
            "acquisitionDate",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        leasing: {
          label: "lbl_leasing",
          name: "leasing",
          placeholder: "lbl_leasing_placeholder",
          decorator: [
            "leasing",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        depreciate: {
          label: "lbl_depreciate",
          name: "depreciate",
          placeholder: "lbl_depreciate_placeholder",
          decorator: ["depreciate"],
        },
      },
    };
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    addNew(type): void {
      let valuesTag = [] as string[];
      valuesTag.push(this.valueAddNew);
      if (this.valueAddNew) {
        let payload = {
          values: valuesTag,
        };
        switch (type) {
          case "type":
            payload["tagId"] = "type";
            break;
          case "model":
            payload["tagId"] = "model";
            break;
          default:
            break;
        }
        assetsServices.addAssetTag(payload).then((data: any) => {
          switch (type) {
            case "type":
              data.forEach(element => {
                this.dataType.push(element);
              });
              break;
            case "model":
              data.forEach(element => {
                this.dataModel.push(element);
              });
              break;
            default:
              break;
          }
        });
      } else {
        switch (type) {
          case "type":
            this.$notification.error({
              message: "Error",
              description: "Please Add Type Name First",
            });
            break;
          case "model":
            this.$notification.error({
              message: "Error",
              description: "Please Add Model Name First",
            });
            break;
          default:
            break;
        }
      }
    },
    getDataAssetTag(valueSearch, id: "type" | "model") {
      this.valueAddNew = valueSearch;
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      // id 1 = type
      // id 2 = model
      if (valueSearch) params.search = `value~*${valueSearch}*`;
      this.loadingTag = true;
      assetsServices
        .getAssetTag(params, id)
        .then(data => {
          switch (id) {
            case "type":
              this.dataType = data.data;
              break;
            case "model":
              this.dataModel = data.data;
              break;
            default:
              break;
          }
        })
        .finally(() => (this.loadingTag = false));
    },
    getListStatus(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        name: `ASSET_ADDITION_STATUS`,
      } as RequestQueryParamsModel;

      if (valueSearch) params.search = `value~*${valueSearch}*`;

      this.loadingStatus = true;
      masterServices
        .listMaster(params)
        .then(data => {
          this.dataListStatus = data;
        })
        .finally(() => (this.loadingStatus = false));
    },
    handleSelectBook(value) {
      this.idBook = value;
      this.getListAssetCategories("", value);
    },
    // getListOfStatus(): void {
    //   let params: any;
    //   params = {
    //     name: "ASSET_STATUS",
    //   } as RequestQueryParamsModel;
    //   masterServices.listMaster(params).then((res: any) => {
    //     res.map((dataObject, index) => (dataObject.key = index));
    //     this.dataListStatus = res;
    //   });
    // },
    getListSupplier(valueSearch) {
      let params = {
        limit: 10,
        page: 0,
        search: `supplier~true`,
      } as RequestQueryParamsModel;
      if (valueSearch)
        params.search += `_AND_firstName~*${valueSearch}*_OR_supplierNumber~*${valueSearch}*_OR_phoneNumber~*${valueSearch}*_OR_email~*${valueSearch}*`;
      if (this.direction) params.sorts = this.sort;
      this.dataSourceSupplier = [];
      this.loadingListSupplier = true;
      contactServices
        .listContactData(params)
        .then((res: ResponseListContactData) => {
          this.dataSourceSupplier = res.data;
        })
        .finally(() => (this.loadingListSupplier = false));
    },
    handleChangeCategory(valueId) {
      this.dataListCategory.forEach(data => {
        if (data.id === valueId) {
          this.form.setFieldsValue({
            depreciationAccount: data.depreciationAccount,
            accumulatedDepreciationAccount:
              data.accumulatedDepreciationAccountId,
          });
        }
      });
    },
    handleCancelFind() {
      this.formFindAsset.resetFields();
    },
    handleCancelPosting() {
      this.form.resetFields();
    },
    assignSearch(key, value, and): string {
      if (key === "kodeUnit" && value) return and + `unitCode~${value}`;
      else if (key === "assetNumber" && value) return and + `assetNo~${value}`;
      else if (key === "serialNumber" && value)
        return and + `serialNumber~${value}`;
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    handleFind() {
      this.formFindAsset.validateFields((err, res) => {
        if (err) return;
        let params = {
          page: 0,
          limit: 10,
          serialNumber: res.serialNumber ?? "",
          unitCode: res.kodeUnit ?? "",
        } as RequestQueryParamsModel;

        this.loadingFind = true;
        assetsServices
          .getFindAddValue(params)
          .then(response => {
            this.dataDetail = response;
            this.form.setFieldsValue({
              assetNumber: response.assetNo,
              book: response.assetBook.id,
              category: response.assetCategory.category.id,
              depreciationAccount: `${response.assetCategory.depreciationExpenseAccount.code} - ${response.assetCategory.depreciationExpenseAccount.description}`,
              accumulatedDepreciationAccount: `${response.assetCategory.accumulatedDepreciationAccount.code} - ${response.assetCategory.accumulatedDepreciationAccount.description}`,
              description: response.description,
              unit: response.unit,
              serialNumber: response.serialNumber,
              unitCode: response.unitCode,
              invoiceNumber: response.invoiceNumber,
              poNumber: response.purchaseOrderNumber,
              supplierName: response.supplierId ? response.supplierId : null,
              warranty: response.warranty,
              model: response.model,
              assetCost: response.originalCost,
              lifeMonths: response.lifeMonths,
              acquisitionDate: response.acquisitionDate,
              leasing: response.leasing,
              depreciate: response.depreciate,
              status: response.status,
              type: response.type,
            });
            this.handleMissingOptSupplier();
          })
          .catch(() => this.form.resetFields())
          .finally(() => (this.loadingFind = false));
      });
    },
    async handleMissingOptSupplier(): Promise<void> {
      try {
        const { supplierName } = this.form.getFieldsValue();
        if (!this.dataSourceSupplier.length || !supplierName) return;

        const found = this.dataSourceSupplier.find(
          item => item.id === supplierName
        );
        if (found) return;
        const res = await useContactListFindById(supplierName);
        this.dataSourceSupplier.push(res);
      } catch (error) {
        this.showNotifError("notif_process_fail");
      }
    },
    checkAndReturnString(value): string {
      if (value) return value;
      else return "";
    },
    getListAssetCategories(valueSearch, idBook) {
      if (!idBook) {
        this.$notification.error({
          message: "Error",
          description: "Select Book First",
        });
      } else {
        let params = {
          page: 0,
          limit: 10,
          search: `book.secureId~*${idBook}*`,
        } as RequestQueryParamsModel;
        if (valueSearch)
          params.search += `_AND_categoryId~*${valueSearch}*_OR_description~*${valueSearch}*`;
        this.loadingListAssetCategory = true;
        assetsServices
          .listAssetCategory(params)
          .then(response => {
            let tempDataSegments = [] as CustomListCategory[];
            response.data.forEach(data => {
              let tempSegment = {
                id: data.id,
                accumulatedDepreciationAccountId: `${this.checkAndReturnString(
                  data.accumulatedDepreciationAccount.code
                )}-${data.accumulatedDepreciationAccount.description}`,
                depreciationAccount: `${this.checkAndReturnString(
                  data.depreciationExpenseAccount.code
                )}-${data.depreciationExpenseAccount.description}`,
                first: "",
                second: "",
                idForView: "",
              } as CustomListCategory;

              let idCategories = data.category.id.split(".");

              idCategories = idCategories.map(dataMap => {
                let temp = dataMap.split("");
                if (temp.length > 0) {
                  temp[0] = temp[0].toUpperCase();
                  dataMap = temp.join("");
                }
                return dataMap;
              });

              tempSegment.idForView = idCategories.join(".");
              tempDataSegments.push(tempSegment);
            });
            this.dataListCategory = tempDataSegments;
          })
          .finally(() => (this.loadingListAssetCategory = false));
      }
    },
    getListAssetBook(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        search: `type~commercial_AND_active~true`,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search = `_AND_search=name~*${valueSearch}*`;
      this.loadingListAssetBook = true;
      assetsServices
        .listAssetBook(params)
        .then(data => {
          this.dataListBook = data.data;
        })
        .finally(() => (this.loadingListAssetBook = false));
    },
    submitForm() {
      this.form.validateFields((err, res) => {
        if (err) return;
        const payload = {
          // acquisitionDate: res.acquisitionDate,
          acquisitionDate: this.dataDetail.acquisitionDate,
          assetStatus: res.status,
          assetCategoryId: res.category,
          assetCost: res.assetCost,
          assetNo: res.assetNumber,
          customerLocation: res.customerLocation || null, // di form ga ada
          // customerName: res.customerName || null, // di form ga ada
          depreciate: this.depreaciateCheckbox,
          description: res.description,
          // employeeName: res.employeeName,
          invoiceNumber: res.invoiceNumber,
          leasing: res.leasing,
          lifeMonths: this.dataDetail.lifeMonths,
          model: res.model,
          warranty: res.warranty,
          // supplier: res.supplierName,
          purchaseOrderNumber: res.poNumber,
          locationId: this.dataDetail.assetLocation.id,
          unitCode: res.unitCode, // unitCode = unitcode
          serialNumber: res.serialNumber,
          unit: res.unit,
          single: false, // ini bukan addition single
          status: "New",
          assetBookId: res.book,
          // assetStatus: res.status,
          capacity: res.capacity,
          customerId: res.customerId ? res.customerId : null,
          employeeId: res.employeeId ? res.employeeId : null,
          supplierId: res.supplierName ? res.supplierName : null,
          fileAttachment: "", // url
          salvageValue: res.salvageValue,
          type: res.type,
        } as RequestAssetAdditions;
        this.loadingPosting = true;
        assetsServices
          .updateAddValue(payload, this.dataDetail.assetId)
          .then(() =>
            this.$notification.success({
              description: Messages.CREATE_SUCCESS,
              message: "Success",
              duration: 30,
            })
          )
          .catch(() =>
            this.$notification.error({
              description: Messages.CREATE_FAIL,
              message: "Error",
              duration: 30,
            })
          )
          .finally(() => (this.loadingPosting = false));
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  created() {
    this.getListAssetBook("");
    this.getListSupplier("");
    this.getListStatus("");
    this.getDataAssetTag("", "type");
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
